const COUNTRIES_NO_PERSONAL_SHOPPER = [ 'CL', 'JP' ];

const ECI_CODE = 'T6';

const STORES_WITH_SUBTITLE = [ 'T875', 'T176', 'D956', 'D449' ];

module.exports = {
  COUNTRIES_NO_PERSONAL_SHOPPER,
  ECI_CODE,
  STORES_WITH_SUBTITLE,
};
