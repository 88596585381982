export function checkWebStorage() {
  if (typeof window === 'undefined') {
    throw new Error('Web storage is only available in the browser');
  }
}
export function saveBondInStorage(bond?: string) {
  checkWebStorage();
  const bondToSave = bond ?? '';
  sessionStorage.setItem('last_purchase_bond', JSON.stringify(bondToSave));
}

export function getBondFromStorage() {
  checkWebStorage();
  const bond = sessionStorage.getItem('last_purchase_bond');
  return bond ? JSON.parse(bond) : '';
}

export function removeBondFromStorage() {
  checkWebStorage();
  sessionStorage.removeItem('last_purchase_bond');
}