import { useMediaQuery } from './useMediaQuery';

/**
 *
 * @param {string} size
 * @returns
 */
export function useIsMobile(size = '768px') {
  return useMediaQuery(`(max-width: ${size})`);
}
