import { any, func, string } from 'prop-types';
import React, { useState, cloneElement } from 'react';

import styles from './style.module.css';

export default function FloatLabel({ label = '', value = '', onChange, children, onFocus, onBlur, className, style }) {
  const [focus, setFocus] = useState(false);

  const triggerChange = (e, childrenOnChange = false) => {
    const newValue = e && e.target ? e.target.value : e;

    onChange?.(newValue);

    if (childrenOnChange) {
      childrenOnChange(newValue);
    }
  };

  const handleOnFocus = () => {
    setFocus(true);
    if (onFocus) onFocus();
  };

  const handleOnBlur = () => {
    setFocus(false);
    if (onBlur) onBlur();
  };

  const labelClass = focus || (value && value.length !== 0) ? `${styles.label} ${styles.labelFloat}` : styles.label;

  return (
    <div className={`${styles.floatLabel} ${className || ''}`} onFocus={handleOnFocus} onBlur={handleOnBlur} style={style}>
      {cloneElement(children, {
        onChange: (e) => {
          triggerChange(e, children.props.onChange);
        },
        value,
      })}
      <label className={labelClass}>{label}</label>
    </div>
  );
}

FloatLabel.propTypes = {
  label: string,
  value: any,
  onChange: func,
  onFocus: func,
  onBlur: func,
  children: any,
  className: string,
  style: any,
};
