import {
  useState, useMemo, useRef, useId,
} from 'react';
import {
  string, bool, object, oneOfType, array, number, any,
} from 'prop-types';

import { IMAGE_SIZE_MAPPER } from 'utils/constants/ux';
import { getSrcSet } from 'utils/helpers';

import styles from './style.module.css';

const ERROR_IMG = '/assets-new/notfound.png';

/**
 *
 * @param {object} props
 * @param {string} props.src
 * @param {string} [props.alt='']
 * @param {string} [props.className='']
 * @param {string} [props.loading='lazy']
 * @param {string} [props.pictureClassName='']
 * @param {object[]} [props.sources=[]]
 *
 * @example
 * <ResponsiveImage
 *   src="/assets-new/img/account/nw-home-mobile.jpg"
 *   alt=""
 *   sources={[
 *     {
 *       srcSet: '/assets-new/img/account/nw-home-mobile.jpg',
 *       media: '(max-width: 767px)',
 *     },
 *   ]}
 * />
 *
 */
export function ResponsiveImage({
  src, alt = '', className, loading = 'lazy', pictureClassName, sources, ...rest
}) {
  const id = useId();
  const pictureRef = useRef(null);
  const stop = useRef(false);
  return (
    <picture ref={pictureRef} className={pictureClassName}>
      {sources?.map((s, idx) => <source key={`${id}-source-${idx}`} {...s} />)}
      <img
        src={src}
        alt={alt}
        className={className}
        loading={loading}
        onError={({ currentTarget }) => {
          if (stop.current) return;
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = ERROR_IMG;
          currentTarget.style.height = '100%';
          currentTarget.style.objectFit = 'scale-down';
          currentTarget.style.background = 'whitesmoke';
          stop.current = true;
        }}
        {...rest}
      />
    </picture>
  );
}
ResponsiveImage.propTypes = {
  src: string.isRequired,
  alt: string,
  className: string,
  pictureClassName: string,
  loading: string,
  sources: array,
};

export const Img = ({ src, alt, otherProps }) => {
  const [ error, setError ] = useState(false);
  if (error) {
    return <img className={styles.imageNotFound} src={ERROR_IMG} />;
  }
  return (
    <img
      onError={() => setError(true)}
      className={styles.image}
      src={src}
      alt={alt}
      {...otherProps}
    />
  );
};

Img.propTypes = {
  src: string,
  alt: string.isRequired,
  otherProps: object,
};
Img.defaultProps = {
  otherProps: {},
};

export const ProgressiveImg = ({ src, alt, otherProps: { iscamperone, ...other } }) => {
  const [ error, setError ] = useState(false);

  if (error) {
    const { className, ...rest } = other;
    return <img className={styles.imageNotFound} src={ERROR_IMG} {...rest} loading='lazy' />;
  }

  if (iscamperone) {
    return <img
      onError={() => setError(true)}
      src={src}
      alt={`Image of ${alt}`}
      {...other}
    />;
  }

  return (
    <picture>
      {/* TO DO: En el futuro, hacer lo mismo que hacemos en el source de webp para image/avif */}
      <source
        type="image/avif"
        srcSet={`
          ${src?.replace('.jpg', '.avif')}
        `}
      />
      <source
        type="image/webp"
        srcSet={`
          ${src?.replace('.jpg', '.webp')}
        `}
      />
      <img
        onError={() => setError(true)}
        src={src}
        alt={`Image of ${alt}`}
        {...other}
      />
    </picture>
  );
};

ProgressiveImg.propTypes = {
  src: string,
  alt: string.isRequired,
  otherProps: object,
};
ProgressiveImg.defaultProps = {
  otherProps: {},
};

const ProgressiveBlurredImg = ({
  highQualitySrc, initLoaded, alt, width, height, index, loading = 'lazy',
}) => {
  const [ isLoaded, setIsLoaded ] = useState(initLoaded);
  const [ error, setError ] = useState(false);
  const isObjectSrcSet = typeof highQualitySrc === 'object' && highQualitySrc !== null;
  const isArraySrcSet = Array.isArray(highQualitySrc);
  const highQualitySrcIsSrcset = Array.isArray(highQualitySrc) || isObjectSrcSet;
  const srcSet = useMemo(() => (isObjectSrcSet && !isArraySrcSet ? highQualitySrc : getSrcSet(highQualitySrc)), [ highQualitySrc, isObjectSrcSet, isArraySrcSet ]);

  if (error) {
    return <picture className={`${styles.image} image`}><img className={`${styles.image} ${styles.imageNotFound}`} src={ERROR_IMG} /></picture>;
  }

  const imagesNotLazy = [ 0, 1 ];

  return (
    <>
      {highQualitySrcIsSrcset ?
        <picture
          className={`${styles.image} ${isLoaded ? '' : styles.imageNotLoaded} image`}
        >
          {/* TO DO: En el futuro, hacer lo mismo que hacemos en el source de webp para image/avif */}
          {
            Object.keys(srcSet).filter((item) => item !== 'xs').map((srcKey) => <source key={`${alt}-${srcKey}`}
                type="image/avif"
                srcSet={`
                  ${srcSet[srcKey]?.replace('.jpg', '.avif')}
                `}
                media={IMAGE_SIZE_MAPPER[srcKey]}
              />)
          }
          {
            Object.keys(srcSet).filter((item) => item !== 'xs').map((srcKey) => <source key={`${alt}-${srcKey}`}
                type="image/webp"
                srcSet={`
                  ${srcSet[srcKey]?.replace('.jpg', '.webp')}
                `}
                media={IMAGE_SIZE_MAPPER[srcKey]}
              />)
          }
          {
            Object.keys(srcSet).filter((item) => item !== 'xs').map((srcKey) => <source key={`${alt}-${srcKey}`}
                type="image/jpg"
                srcSet={`
                  ${srcSet[srcKey]}
                `}
                media={IMAGE_SIZE_MAPPER[srcKey]}
              />)
          }
          <img
            onLoad={() => setIsLoaded(true)}
            onError={() => setError(true)}
            src={srcSet.md}
            width={width}
            height={height}
            alt={alt}
            className={isLoaded ? styles.image : `${styles.image} ${styles.imageNotLoaded}`}
            loading={!loading || imagesNotLazy.includes(index) ? undefined : loading}
          />
        </picture> :
        <img
          onLoad={() => setIsLoaded(true)}
          onError={() => setError(true)}
          className={isLoaded ? styles.image : `${styles.image} ${styles.imageNotLoaded}`}
          src={highQualitySrc}
          alt={alt}
          loading={!loading || imagesNotLazy.includes(index) ? undefined : loading}
        />
      }
    </>
  );
};

ProgressiveBlurredImg.propTypes = {
  lowQualitySrc: string,
  highQualitySrc: oneOfType([ string, array, object ]),
  alt: string.isRequired,
  initLoaded: bool,
  sizes: string,
  width: number,
  height: number,
  index: number,
  loading: any,
};

ProgressiveBlurredImg.defaultProps = {
  initLoaded: false,
  sizes: '(min-width: 1440px) 25vw, (min-width: 768px) 33vw, 50vw',
  width: 326,
  height: 489,
  loading: 'lazy',
};

export default ProgressiveBlurredImg;
