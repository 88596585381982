module.exports = {
  collections: [
    'novelties',
    'sales',
    'bestsellers',
    'collection',
  ],
  typologies: [ 'typology' ],
  lines: [ 'line' ],
  concepts: [ 'concept' ],
  lineCollections: [ 'lineCollection' ],
  sizes: [ 'size' ],
  colors: [ 'color' ],
  prices: [ 'price' ],
  sustainabilities: [ 'sustainability' ],
};
