export const getIdAnalyticsStoreUA = (c) => {
  // Propiedad 07. Global Europe

  const country = c ? c.toUpperCase() : '';

  if (country.match('(FR|BE|LU|DE|AT|CH|PL|ES|PT|AD|IE|XI|NL|GB|DK|FI|NO|SE|RU|TR|IT|GR|BA|CY|CZ|EE|LV|LI|LT|MT|RO|SK|SI|MC|AL|RS)')) {
    return 'UA-1455414-20';
  }
  // Propiedad 10. Global America
  if (country.match('(CA|US|MX|BR|CO|EC|CL|PE)')) {
    return 'UA-1455414-23';
  }
  // Propiedad 13. Global Asia
  if (country.match('(AU|CN|JP|NZ|KR|HK|SG|TW|IN|TH|VN|ID)')) {
    return 'UA-1455414-26';
  }
  // Default
  return 'UA-1455414-34';
};

export const getIdAnalyticsCountryUA = (c) => {
  const country = c ? c.toUpperCase() : '';

  // Propiedad 02. France & benelux
  if (country.match('(FR|BE|LU)')) {
    return 'UA-1455414-15';
  }
  // Propiedad 03. GASP
  if (country.match('(DE|AT|CH|PL)')) {
    return 'UA-1455414-16';
  }
  // Propiedad 04. IBERIA
  if (country.match('(ES|PT|AD)')) {
    return 'UA-1455414-17';
  }
  // Propiedad 05. UKISH
  if (country.match('(IE|XI|NL|GB|DK|FI|NO|SE)')) {
    return 'UA-1455414-18';
  }
  // Propiedad 06. ROW - Europa
  if (country.match('(RU|TR|IT|GR|BA|CY|CZ|EE|LV|LI|LT|MT|RO|SK|SI|MC|AL|RS)')) {
    return 'UA-1455414-19';
  }
  // Propiedad 08. America
  if (country.match('(CA|US)')) {
    return 'UA-1455414-21';
  }
  // Propiedad 09. ROW - America
  if (country.match('(MX|BR|CO|EC|CL|PE)')) {
    return 'UA-1455414-22';
  }
  // Propiedad 11. Asia
  if (country.match('(AU|CN|JP|NZ|KR|TH)')) {
    return 'UA-1455414-24';
  }
  // Propiedad 12. ROW - Asia
  if (country.match('(HK|SG|TW|IN|VN|ID)')) {
    return 'UA-1455414-25';
  }
  // Default: Propiedad 14. ROW - Other
  return 'UA-1455414-27';
};
