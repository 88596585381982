/* eslint-disable no-nested-ternary */
/* eslint-disable no-new */
import { useCallback } from 'react';

import { useI18n } from 'context/i18n';

/**
 *
 * @param {string} s - string to test
 * @returns {boolean}
 */
function startsWithLocale(s) {
  return (/^(\/)?[a-z]{2}-[A-Z]{2}|(\/)?int\//).test(s);
}
export function useLink() {
  const { locale } = useI18n();

  const appendLocale = useCallback(
    /**
     *
     * @param {string} href
     * @returns {string} href with locale appended when needed
     */
    (href) => {
      if (!href || href.startsWith('#')) return href;
      try {
        // with this we can know if href is a full url
        new URL(href);
        return href;
      } catch (_) {
        // if href is relative we append locale
        return startsWithLocale(href) ? href : href === '/' ? `/${locale}` : `/${locale}${href}`;
      }
    },
    [ locale ],
  );
  return appendLocale;
}
