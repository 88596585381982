const baseTranslations = {
  target: {
    // Respetar siempre el orden Hombre, Mujer, niños
    es: ['hombre', 'mujer', 'kids', 'goods', 'all'],
    mx: ['hombre', 'mujer', 'kids', 'goods', 'all'],
    en: ['men', 'women', 'kids', 'goods', 'all'],
    fr: ['homme', 'femme', 'enfant', 'goods', 'all'],
    de: ['herren', 'damen', 'kinder', 'goods', 'all'],
    it: ['uomo', 'donna', 'bambino', 'goods', 'all'],
  },
  product: {
    es: ['zapatos', 'bolsos-accesorios', 'ropa', 'campergoods', 'all'],
    mx: ['zapatos', 'bolsos-accesorios', 'ropa', 'campergoods', 'all'],
    en: ['shoes', 'bags-accessories', 'apparel', 'campergoods', 'all'],
    fr: ['chaussures', 'sacs-accessoires', 'vetement', 'campergoods', 'all'],
    de: ['schuhe', 'taschen-accessoires', 'kleidung', 'campergoods', 'all'],
    it: ['scarpe', 'borse-accesori', 'abbigliamento', 'campergoods', 'all'],
  },
};

const countryMapper = {
  EU: ['DE', 'ES', 'LI', 'CH', 'CA'],
  US: ['US', 'AU'],
  UK: ['UK'],
  GB: ['GB', 'XI'],
  JP: ['JP'],
  KR: ['KR'],
  MX: ['MX'],
  RU: ['RU'],
  BR: ['BR'],
};

/**
 * @function getBaseTransations
 * @description Returns translations for target and product
 * @param {string} langCode ISO code of region (es_ES) or generic iso code (es)
 * @param {string} key key of the translation: Current: 'target', 'product'
 * @returns {string}
 */
function getBaseTranslations(langCode, key) {
  // intentamos buscar por codigo iso - region => e.g: es_ES
  // sino intentamos buscar por codigo iso - generico => e.g: es
  // sino devolvemos idioma por defecto => e.g: en
  if (Object.keys(baseTranslations).includes(key)) {
    if (Object.keys(baseTranslations[key]).includes(langCode)) {
      return JSON.parse(JSON.stringify(baseTranslations[key][langCode]));
    }
    if (langCode.includes('_') && Object.keys(baseTranslations[key]).includes(langCode.split('_')[0])) {
      return JSON.parse(JSON.stringify(baseTranslations[key][langCode.split('_')[0]]));
    }
    return JSON.parse(JSON.stringify(baseTranslations[key].es));
  }
  return Error('Provided key not in base translations');
}

function getCountryFromMarket(market) {
  const countryCode = Object.keys(countryMapper).filter((country) => countryMapper[country].includes(market));
  if (countryCode.length) {
    return countryCode[0];
  }
  return 'EU';
}

const defaultLocale = 'en_ES';

module.exports = {
  baseTranslations,
  getCountryFromMarket,
  getBaseTranslations,
  countryMapper,
  defaultLocale,
};
