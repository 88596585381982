const ASIAN_MARKETS = [ 'TW', 'TH', 'KR', 'JP', 'HK' ];

const CLOUDFRONT_MISS = 'Miss from cloudfront';

const FAKE_LANGUAGES = {
  es: [ 'mx', 'co', 'pe', 'cl' ],
};

const FAKE_LANG_LIST = [ 'mx', 'co', 'pe', 'cl' ];

const MARKETS_WITH_COUNTRIES_SELECTOR = [ 'CA', 'DE', 'EL', 'EN', 'ES', 'FR', 'IT', 'NL', 'PL', 'PT' ];

const BRITISH_COUNTRIES = [ 'GB', 'XI' ];

const FAKE_LANG_MAP = {
  mx: 'es',
  co: 'es',
  pe: 'es',
  cl: 'es',
};

module.exports = {
  ASIAN_MARKETS,
  CLOUDFRONT_MISS,
  FAKE_LANGUAGES,
  FAKE_LANG_LIST,
  MARKETS_WITH_COUNTRIES_SELECTOR,
  BRITISH_COUNTRIES,
  FAKE_LANG_MAP,
};
