const AUTO_BONDS = {
  APPLY: '1',
  DOESNT_APPLY: '0',
};

const BAG_EXPIRATION_DAYS = 30;

module.exports = {
  AUTO_BONDS,
  BAG_EXPIRATION_DAYS,
};
