import type { TOrder, TOrderPrice } from "types/order";

export function normalizeOrder(order: TOrder): TOrder {
  // de momento el unico cambio que hay que hacer es modificar los productos de kboix. Sumar los precios de las plantillas y ponerlo en el del casco.
  const copiedOrder = JSON.parse(JSON.stringify(order)) as TOrder;
  if (!order.kboix) {
    return copiedOrder;
  }
  const parent = order.products.find((product) => Array.isArray(product.additionalProducts) && product.additionalProducts.length !== 0)
  if (parent) {
    const newProducts = order.products.reduce((acc, product) => {
      if (product.id === parent.id) {
        return acc;
      }
      if (parent.additionalProducts.includes(product.id)) {
        acc[0].prices = sumOrderPrice(acc[0].prices, product.prices);
        product.parentId = parent.id;
        acc.push(product);
        return acc;
      }
    }, [parent])
    copiedOrder.products = newProducts;
  }
  return copiedOrder;
}

export function sumOrderPrice(a: TOrderPrice, b: TOrderPrice) {
  return {
    ...a,
    current: a.current + b.current,
    currentEur: a.currentEur + b.currentEur,
    previous: a.previous + b.previous,
    discountEur: a.discountEur + b.discountEur,
    discountLocal: a.discountLocal + b.discountLocal,
    currentFormated: `${(a.current + b.current).toFixed(2)} ${a.currency}`,
    previousFormated: `${(a.previous + b.previous).toFixed(2)} ${a.currency}`,
    totalPrice: a.totalPrice + b.totalPrice,
    netPrice: a.netPrice + b.netPrice,
    netPriceEur: a.netPriceEur + b.netPriceEur,
    taxTotal: a.taxTotal + b.taxTotal,
    intercoPrice: a.intercoPrice + b.intercoPrice,
  }
}