const SIZE_GUIDES_ORDER = [
  'XS',
  'S',
  'M',
  'L',
  'XL',
  'XXL',
];

const SIZE_GUIDE_WITH_MODELS = [ 'KU10025-001', 'KU10025-002', 'KU10026-001', 'KU10028-001',
  'KU10028-002', 'KU10030-001', 'KU10030-002', 'KU10032-001',
  'KU10032-002', 'KU10033-001', 'KU10033-002',
];

const STANDARD_SIZE_GUIDE = [ 'EU', 'US', 'UK' ];

const TARGET_SIZE_LABEL_MARKETS = [
  'US', 'GB', 'XI'
];

module.exports = {
  SIZE_GUIDES_ORDER,
  SIZE_GUIDE_WITH_MODELS,
  STANDARD_SIZE_GUIDE,
  TARGET_SIZE_LABEL_MARKETS,
};
