import {
  useState, useEffect, useRef, cloneElement,
} from 'react';
import { AutoComplete } from 'antd';
import {
  array, func, string, number, any, bool,
} from 'prop-types';

const Complete = ({
  baseOptions, onChange, id, inputValue, dropdownClassName, maxLength, virtual,
  dropdownOnFocus, renderCustomInput, onSelectProp, disabled,
}) => {
  const [ options, setOptions ] = useState(dropdownOnFocus ? baseOptions : []);

  const onSearch = (searchText) => {
    const searchStr = searchText ? searchText.toUpperCase().trim() : '';
    const optionsToSet = !searchStr || searchStr.length < 3 ?
      [] :
      baseOptions.filter((item) => item.value.toUpperCase().trim().indexOf(searchStr) !== -1);
    setOptions(optionsToSet);
  };

  const onSelect = (data) => {
    onChange(data);
  };

  const handleOnChange = (data) => {
    onChange(data);
  };

  if (renderCustomInput) {
    const [ openDropDown, setOpenDropDown ] = useState(false);
    const focused = useRef(false);

    useEffect(() => {
      setOptions(baseOptions);
    }, [ baseOptions ]);

    return <AutoComplete
      disabled={disabled}
      open={openDropDown}
      options={options}
      onSelect={onSelectProp ? (data) => {
        const selectedFullObject = options.filter((item) => item.value === data)[0];
        setOpenDropDown(false);
        onSelectProp(selectedFullObject);
      } : onSelect}
      onSearch={onSearch}
      placeholder=""
      onChange={handleOnChange}
      id={id}
      searchValue={inputValue || ''}
      dropdownClassName={dropdownClassName || null}
      maxLength={maxLength || -1}
    >
      {
        cloneElement(renderCustomInput,
          {
            onClick: () => {
              setOpenDropDown(!focused.current);
              focused.current = !focused.current;
            },

            onFocus: () => {
              setOpenDropDown(true);
            },

            onBlur: () => {
              setOpenDropDown(false);
              focused.current = false;
            },
          })
      }
    </AutoComplete>;
  }

  return (
    <>
      <AutoComplete
        autoComplete="nope"
        onSelect={onSelect}
        onSearch={onSearch}
        placeholder=""
        onChange={handleOnChange}
        id={id}
        searchValue={inputValue || ''}
        dropdownClassName={dropdownClassName || null}
        maxLength={maxLength || -1}
        virtual={virtual !== null && virtual !== undefined ? virtual : false}
      >
        {
          options.map(({ value }, index) => (
            <AutoComplete.Option key={`${value}-${index}`} value={value}>
              {value}
            </AutoComplete.Option>
          ))
        }
      </AutoComplete>
    </>
  );
};

Complete.propTypes = {
  baseOptions: array.isRequired,
  onChange: func,
  id: string,
  inputValue: string,
  dropdownClassName: string,
  maxLength: number,
  renderCustomInput: any,
  onSelectProp: any,
  disabled: bool,
  virtual: bool,
  dropdownOnFocus: bool, // Si true, en el onFocus salen en seguida las opciones
};

Complete.defaultProps = {
  renderCustomInput: false,
  onSelectProp: false,
  disabled: false,
  onChange: () => true,
};

export default Complete;
