import { get } from 'utils/apiWrapper';
import { prepareHeadersFromContext, DEFAULT_LIMIT } from 'utils/helpers';
import { PRODUCTS_SEARCH, PRODUCTS_SUGGESTIONS, SEARCH_GRID_SEEL_ALL, SEARCH_URLS } from 'utils/routes';

export const searchProducts = ({ context, lang, query }) =>
  new Promise((resolve, reject) => {
    const path = `${PRODUCTS_SEARCH}?profile=${lang}&query=${query}`;
    const headers = prepareHeadersFromContext(context);

    get({ path, headers })
      .then(({ data }) => {
        const { status, products } = data;
        if (status !== 'OK') {
          resolve(false);
        }

        return resolve({
          status,
          products,
        });
      })
      .catch(reject);
  });

export const searchProductsUnbxd = ({ lang, market, query, filter = '', sort, page, uid, ipAddress, translations }) =>
  new Promise((resolve, reject) => {
    // translations tiene que existir y tener 2 valores diferentes de '' siempre
    // ya lo comprobamos para setear el booleano de searchNew pero por si acaso
    if (!translations || !Array.isArray(translations) || translations.length !== 2 || translations.some((item) => item === '')) {
      console.error('Missing required keys for unbxd API.');
      reject(translations);
    }
    const fields = [
      `${market}_currency_unx_ts`,
      `${market}_is_on_sale_unx_ts`,
      `${market}_max_price_EUR_unx_d`,
      `${market}_max_price_unx_d`,
      `${market}_min_price_EUR_unx_d`,
      `${market}_min_price_unx_d`,
      `${market}_max_sale_price_unx_d`,
      `${market}_min_sale_price_unx_d`,
      `${market}_max_sale_price_EUR_unx_d`,
      'age_group',
      'title',
      `${market}_${lang.toLowerCase()}_productUrl`,
      'material',
      'is_shooting_new',
      'color',
      'gender',
      `${lang.toLowerCase()}_target`,
      'grid_image_suffix',
      `${market}_voucher_prelaunch`,
      `${market}_voucher_code`,
      `${market}_voucher_type`,
      `${market}_sale_percentage`,
      `${market}_voucher_discount_unx_ts`,
      `${market}_max_voucher_price_unx_ts`,
      `${market}_min_voucher_price_unx_ts`,
    ];
    const sizesFacet = `&facet.field=${market}_available_sizes_unx_tsm&f.${market}_available_sizes_unx_tsm.facet.sort=index&f.${market}_available_sizes_unx_tsm.facet.limit=50&f.${market}_available_sizes_unx_tsm.facet.display.name=Size&f.${market}_available_sizes_unx_tsm.facet.position=4`;
    const sortMap = {
      price_asc: 'asc',
      price_desc: 'desc',
    };
    let querySort = '';
    if (sort && sort.length && sort !== 'default') {
      querySort = `&sort=${market}_min_price_EUR_unx_d%20${sortMap[sort]}`;
    }
    const baseApiKeyUnbxd = `/${translations.join('/')}/`;
    const path = `${baseApiKeyUnbxd}search?q=${encodeURIComponent(query)}&variants=false&facet.multiselect=true${sizesFacet}&filter=available_in:${market}${filter}&fields=${fields.join(',')}&rows=${DEFAULT_LIMIT}&page=${page}${querySort}${uid ? `&uid=${uid}` : ''}`;
    const rqHeaders = {
      'unbxd-user-id': uid,
    };
    if (ipAddress) {
      rqHeaders['X-forwarded-for'] = ipAddress;
    }
    get({ path, base: 'https://search.unbxd.io', headers: rqHeaders })
      .then((res) => {
        const unbxdRequestId = res?.headers ? res?.headers['unx-request-id'] || '' : '';
        const { data } = res;
        const { response, facets, searchMetaData } = data;
        if (!response) {
          resolve(false);
        }
        const { products, numberOfProducts } = response;
        let fallback = null;
        if (searchMetaData.fallback && Object.keys(searchMetaData.fallback).length > 0) {
          fallback = {
            query: searchMetaData.fallback.q,
            original: searchMetaData.fallback.original.q,
          };
        }
        return resolve({
          products,
          unbxd: true,
          facets,
          numberOfProducts,
          unbxdRequestId,
          fallback,
        });
      })
      .catch(reject);
  });

export const searchSuggestions = ({ lang, query }) =>
  new Promise((resolve, reject) => {
    const path = `${PRODUCTS_SUGGESTIONS}?profile=${lang}&query=${query}`;
    get({ path })
      .then(({ data }) => {
        const { status, suggestions } = data;
        if (status !== 'OK') {
          reject(status);
        } else {
          resolve({
            status,
            suggestions,
          });
        }
      })
      .catch(reject);
  });

export const searchGridSeeAll = ({ context, profile }) =>
  new Promise((resolve, reject) => {
    const path = `${SEARCH_GRID_SEEL_ALL}?profile=${profile}`;
    const headers = prepareHeadersFromContext(context);

    get({ path, headers })
      .then(({ data }) => {
        const { status } = data;

        if (status === 'OK') {
          return resolve(data);
        }

        return reject(data);
      })
      .catch(reject);
  });

export const searchUrls = ({ context, profile }) =>
  new Promise((resolve, reject) => {
    const path = `${SEARCH_URLS}?profile=${profile}`;
    const headers = prepareHeadersFromContext(context);

    get({ path, headers })
      .then(({ data }) => {
        const { status } = data;

        if (status === 'OK') {
          return resolve(data);
        }

        return reject(data);
      })
      .catch(reject);
  });

export async function autosuggestUnbxd(lang, market, query, translations, uid) {
  // translations tiene que existir y tener 2 valores diferentes de '' siempre
  // ya lo comprobamos para setear el booleano de searchNew pero por si acaso
  try {
    if (!translations || !Array.isArray(translations) || translations.length !== 2 || translations.some((item) => item === '')) {
      console.error('Missing required keys for unbxd API.');
      return false;
    }

    const fields = [
      'title',
      'line',
      'imageUrl',
      'image_url',
      'salePrice',
      `${market}_${lang.toLowerCase()}_productUrl`,
      'price',
      `${market}_max_price_unx_d`,
      `${market}_max_sale_price_unx_d`,
      'currency',
      `${market}_currency_unx_ts`,
      `${lang.toLowerCase()}_product_sheet_h1`,
      'uniqueId',
      'id',
      'autosuggest',
      'doctype',
      'product_sheet_H1',
      `${market}_sale_percentage`,
      'age_group',
      'material',
      'is_shooting_new',
      'color',
      'gender',
      `${lang.toLowerCase()}_target`,
      `${market}_voucher_prelaunch`,
      `${market}_voucher_code`,
      `${market}_voucher_type`,
      `${market}_voucher_discount_unx_ts`,
      `${market}_max_voucher_price_unx_ts`,
      `${market}_min_voucher_price_unx_ts`,
    ];

    const config = {
      version: 'V2',
      'inFields.count': 0,
      'popularProducts.count': 6,
      'keywordSuggestions.count': 12,
      'topQueries.count': 2,
      'promotedSuggestion.count': 12,
      'popularProducts.fields': fields.join(','),
      'popularProducts.filter': `available_in:"${market}"`,
      variants: false,
      fallback: true,
      'fallback.method': 'fuzzySearch',
      indent: 'off',
    };

    if (uid) {
      config.uid = uid;
    }

    const baseApiKeyUnbxd = `/${translations.join('/')}/`;
    const configString = new URLSearchParams(config).toString();
    const path = `${baseApiKeyUnbxd}autosuggest?q=${encodeURIComponent(query)}&${configString}`;

    const rqHeaders = {
      'unbxd-user-id': uid,
    };

    const result = await get({ path, base: 'https://search.unbxd.io', headers: rqHeaders });
    const unbxdRequestId = result?.headers ? result?.headers['unx-request-id'] || '' : '';
    const { data } = result;
    const { response, facets, searchMetaData } = data;

    if (!response) {
      return false;
    }

    const { products, numberOfProducts } = response;
    let fallback = null;
    if (searchMetaData.fallback && Object.keys(searchMetaData.fallback).length > 0) {
      fallback = {
        query: searchMetaData.fallback.q,
        original: searchMetaData.fallback.original.q,
      };
    }
    return {
      products,
      unbxd: true,
      facets,
      numberOfProducts,
      unbxdRequestId,
      fallback,
    };
  } catch (e) {
    console.error(e);
    return false;
  }
}
