import { useI18n } from 'context/i18n';
import { useCallback } from 'react';
import { getMarket } from 'utils/helpers';
import rules from 'utils/rules';

export function useValidate() {
  const { t, locale, profileData } = useI18n();
  const market = getMarket(locale);
  const { config } = profileData;
  const marketWithSpecialChars = config?.allow_special_chars || false; // originalmente era un array de front => [ 'PL', 'GR', 'HK', 'RS' ];

  const validate = useCallback((ruleKey, overrideRequired = null) => {
    // We locate the rule in the rules object
    const rule = rules[ruleKey];
    // If the rule is not implemented, we log an error and return undefined
    if (!rule) {
      console.error(`Rule ${ruleKey} is not implemented`);
      return undefined;
    }
    // If the rule is implemented, we check if it has a market filter and if the current market is included in the filter
    if (rule.markets && !rule.markets.includes(market)) return undefined;

    const returnRule = rule(t, locale);

    if (overrideRequired !== null) {
      returnRule[0].required = overrideRequired;
    }

    // If the store does not allow strange characters and the market is not in the , we add the rule for strange characters to the array
    if (!marketWithSpecialChars) {
      const rulesInsideRule = rule(() => {}, locale);

      if (Array.isArray(rulesInsideRule) && rulesInsideRule.some((r) => r.type === 'string')) {
        return [ ...returnRule, {
          type: 'string',
          // eslint-disable-next-line no-control-regex
          pattern: /^[\x00-\xFF]+$/, // del 0 al 255 (decimal en hex) de la tabla ascii
          message: t('compra.formulario', 'alerta.descripcion', 'Detected characters not allowed.'),
        } ];
      }
    }

    return returnRule;
  }, []);

  return validate;
}
