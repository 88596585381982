import { useEffect, useState } from 'react';

const getMatches = (query) => {
  // Prevents SSR issues
  if (typeof window !== 'undefined') {
    return window.matchMedia(query).matches;
  }
  return false;
};

export function useMediaQuery(query) {
  const [ matches, setMatches ] = useState(getMatches(query));

  function handleChange() {
    setMatches(getMatches(query));
  }

  useEffect(() => {
    const matchMedia = window.matchMedia(query);

    // Triggered at the first client-side load and if query changes
    setMatches(getMatches(query));

    // Listen matchMedia

    // Sorpresita, hay ipads que usan un safari anticuado donde MediaQueryList
    // no tiene addEventListener en su proto (causa un unexpected error) sino que tiene
    // la versión deprecated addListener

    if (matchMedia?.addEventListener) {
      matchMedia.addEventListener('change', handleChange);
    } else if (matchMedia?.addListener) { // CASOS DEPRECATED QUE MANTENGA FUNCIONALIDAD
      matchMedia.addListener(handleChange);
    } else {
      console.info('[MATCH MEDIA ERROR] - NO VALID LISTENER IN MATCH');
      console.info('Details:');

      if (typeof window !== 'undefined') {
        console.info('Navigator:', window.navigator);
      }

      console.info('Match media:', matchMedia);
    }

    return () => {
      if (matchMedia?.removeEventListener) {
        matchMedia.removeEventListener('change', handleChange);
      } else if (matchMedia?.removeListener) {
        matchMedia.removeListener(handleChange);
      } else {
        console.info('Match media:', matchMedia);
      }
    };
  }, [ query ]);

  return matches;
}
