export const kboixKeys = {
  suggestions: 'kboix_suggestions',
} as const;

export const kboixValueMap = {
  type: {
    ultra: 1,
    medium: 2,
    short: 3,
  },
  gender: {
    W: 1,
    M: 2,
  },
  goals: {
    racing: 1,
    fit: 2,
  },
  tread: {
    pronation: 1,
    neutral: 2,
    supination: 3,
  },
  mode: {
    rearfoot: 1,
    midfoot: 2,
    forefoot: 3,
  },
  foot_width: {
    wide: 1,
    narrow: 2,
  },

} as const;

export const kboixValueNameMap = {
  type: {
    1: 'Ultra',
    2: 'Medium',
    3: 'Short',
  },
  gender: {
    1: 'Woman',
    2: 'Man',
  },
  goals: {
    1: 'Racing',
    2: 'Fit',
  },
  weight_range: {
    1: '< 65 kg',
    2: '65-80 kg',
    3: '> 80 kg',
  },
} as const;

export const kboixTipsValueMap = {
  GENERAL: 0,
  TRAINING_SCHEDULE: 1,
  NEUROMUSCULAR: 2,
  NUTRITION: 3,
  CARBOHYDRATES: 4,
  ALTITUDE: 5,
  HEAT: 6,
} as const;
export const kboixTipsValueNameMap = {
  0: 'General',
  1: 'Training Schedule',
  2: 'Neuromuscular',
  3: 'Nutrition',
  4: 'Carbohydrates',
  5: 'Altitude',
  6: 'Heat',
} as const;
