import { createContext, useState, useContext } from 'react';
import {
  node, string, oneOfType, bool,
} from 'prop-types';

import InternalErrorPage from 'pages/500';

const FallbackUI = ({ message }) => (
  <div>
    <h2>An error ocurred!</h2>
    <h4>{message}</h4>
  </div>
);

FallbackUI.propTypes = {
  message: string.isRequired,
};

export const ErrorContext = createContext();

export const ErrorProvider = ({ error, children }) => {
  const [ localError, setLocalError ] = useState(false);

  const spreadError = (message) => {
    // para errores que React no detecta
    console.error('React Error in Client  => ', message.toString());
    setLocalError(message.toString());
  };

  const firstChildDisplayName = children[0]?.type?.displayName;

  if (error === false && localError === false && firstChildDisplayName !== 'ErrorPage') {
    try {
      return (
        <ErrorContext.Provider value={{ spreadError }}>
          { children }
        </ErrorContext.Provider>
      );
    } catch (e) {
      return <InternalErrorPage />;
    }
  }
  return <InternalErrorPage />;
};

ErrorProvider.propTypes = {
  children: node,
  error: oneOfType([ string, bool ]),
};

export function useErrorContext() {
  const context = useContext(ErrorContext);
  if (context === undefined) {
    throw new Error('useI18n must be used within a I18nProvider');
  }
  return context;
}
