/* check if there is a cookie for the gb country
** if there is a cookie, redirect to that country
** if there is no country show the modal
** on select country set cookie and redirect if necessary
*/
import { useEffect, useState } from 'react';
import { Modal, Button } from 'antd';
import { useI18n } from 'context/i18n';
import { COOKIES, getCookie } from 'utils/helpers';
import { useLocale } from 'hooks';
import { useCountries } from 'hooks/useCountries';
import { IS_NNORMAL } from 'utils/constants/system';
import { BRITISH_COUNTRIES } from 'utils/constants/i18n';
import { setGBCookie } from 'utils/i18n';

import styles from './style.module.css';

function GreatBritainModal() {
  if (IS_NNORMAL) return null;
  const { t } = useI18n();
  const [ visible, setVisible ] = useState(false);
  const locale = useLocale();
  const {
    isBritishCountry, getCountriesData,
  } = useCountries();

  useEffect(() => {
    if (!locale.country || !isBritishCountry()) return;
    const cookieCountry = getCookie(COOKIES.GB_COUNTRY);
    if (cookieCountry === 'GB' || cookieCountry === 'XI') {
      setGBCookie(locale.country);
      return;
    }
    setVisible(true);
  }, [ locale.country ]);

  const handleSelectCountry = (code) => {
    setGBCookie(code);
    if (code === locale.country) {
      setVisible(false);
    } else {
      window.location.assign(`${window.location.origin}${code === 'XI' ? '/en_XI' : '/en_GB'}`);
    }
  };

  return <Modal
      className={`${styles.gbModal}`}
      width={'700px'}
      title={null}
      visible={visible}
      footer={false}
      closable={false}
      destroyOnClose={true}
    >
      <p className={styles.modalTitle}>
        {t('modal.gb', 'welcome', 'Welcome')} <br />
        {t('modal.gb', 'choose.country.lang', 'Do you want to see the store for Northern Ireland or the United Kingdom?')}
      </p>
      <div className={styles.countries}>
        {getCountriesData(BRITISH_COUNTRIES)?.map((country) => (
          <Button className={styles.countryButton} key={country.id} onClick={() => handleSelectCountry(country.id)}>
            {country.name}
          </Button>
        ))}
      </div>
    </Modal>;
}
export default GreatBritainModal;
