const IS_CAMPER = process.env.NEXT_PUBLIC_DOMAIN === 'camper';
const IS_NNORMAL = process.env.NEXT_PUBLIC_DOMAIN === 'nnormal';

// key code númerico está deprecatd
const KEYCODES = {
  arrowDown: 'ArrowDown',
  arrowUp: 'ArrowUp',
  enter: 'Enter',
  escape: 'Escape',
  space: 'Space',
  tab: 'Tab',
};

const ADYEN_STATUS = {
  HANDLE_ACTION: 'HANDLE_ACTION',
  PENDING_3DS2: 'PENDING_3DS2',
  REDIRECT_HPP: 'REDIRECT_HPP',
  PENDING_REDIRECT: 'PENDING_REDIRECT',
};

const PAYMENT_CODES = {
  TARJ: 'ADYEN_CARD',
};

const PAYPAL_CODES = ['PPLE', 'PPLB', 'PPLY'];

const TIER_POSITIONS = {
  STANDALONE: 'standalone',
  STANDALONE_LEFT: 'standalone-left',
  STANDALONE_BOTTOM: 'standalone-bottom',
  STANDALONE_BOTTOM_SEO: 'standalone-bottom-seo',
  CAMPAIGN: 'campaign',
  CAMPAIGN_BOTTOM: 'campaign-bottom',
  GRID: 'grid',
};

const CAMPAIGN_COLLECTION_LIMIT = 3;

const ERROR_IMG = '/assets-new/notfound.png';

const SIZE_GUIDES_ORDER = ['XS', 'S', 'M', 'L', 'XL', 'XXL'];

const SOCKS_TYPE = 'TALLAP';

// @TODO Lo bonito sería tener un const por dominio para separar los SKU
const GIFT_CARD_CODES = [
  // Camper
  'PR148-001',
  'PR148-002',
  'PR148-003',
  'PR148-004',
  'PR148-005',
  // NNormal
  'PN007-001',
];

const ERROR_MINIMUM_AMOUNT = 'ERROR_MINIMUM_AMOUNT';

const SHIPPING_EXPRESS = '02';
const PICK_UP_IN_STORE = '99';
const PACKSTATION = '04';
const CORREOS = '05';
const POSTFILIALE = '06';
const STANDARD = '01';
const RETURN_TO_STORE = '06';
const RETURN_TO_DROPOFF = '05';
const SAME_DAY_DELIVERY = '03';

const SAME_DAY_DELIVERY_DISTRICTS = [
  'ATAŞEHİR',
  'AVCILAR',
  'BAĞCILAR',
  'BAHÇELİEVLER',
  'BAKIRKÖY',
  'BAŞAKŞEHİR',
  'BAYRAMPAŞA',
  'BEŞİKTAŞ',
  'BEYLİKDÜZÜ',
  'BEYOĞLU',
  'ÇEKMEKÖY (Excluded: ALEMDAĞ MAH, ÇATALMEŞE MAH, NİŞANTEPE MAH, HÜSEYİNLİ MAH, KOÇULLU MAH, ÖMERLİ MAH, REŞADİYE MAH, SIRAPINAR MAH)',
  'ESENLER',
  'ESENYURT',
  'EYÜPSULTAN (Excluded: MİTHATPAŞA MAH, AĞAÇLI MAH, AKPINAR MAH, ÇİFTALAN MAH, IŞIKLAR MAH, İHSANİYE MAH, ODAYERİ MAH, PİRİNÇÇİ MAH)',
  'FATİH',
  'GAZİOSMANPAŞA',
  'GÜNGÖREN',
  'KADIKÖY',
  'KAĞITHANE',
  'KARTAL',
  'KÜÇÜKÇEKMECE',
  'MALTEPE',
  'PENDİK (Excluded: BALLICA MAH, EMİRLİ MAH, GÖÇBEYLİ MAH, KURNA MAH, KURTDOĞMUŞ MAH)',
  'SANCAKTEPE (Excluded: PAŞAKÖY MAH)',
  'SARIYER (Excluded: BAHÇEKÖY KEMER MAH, DEMİRCİKÖY MAH, GARİPÇE MAH, KISIRKAYA MAH, KISIRKAYA MAH, KUMKÖY  MAH (KİLYOS MAH), RUMELİFENERİ MAH, RUMELİKAVAĞI MAH, USKUMRUKÖY MAH, YENİ MAH, GÜMÜŞDERE MAH, KAZIM KARABEKİR PAŞA MAH, MADEN MAH, SARIYER MERKEZ MAH)',
  'SULTANGAZİ',
  'ŞİŞLİ',
  'ÜMRANİYE (Excluded: DUMLUPINAR MAH, HEKİMBAŞI MAH, TOPAĞACI MAH)',
  'ÜSKÜDAR',
  'ZEYTİNBURNU',
];

const TARGETS_TRANSLATIONS = {
  W: ['generico', 'target.mujer', 'Women'],
  M: ['generico', 'target.hombre', 'Men'],
  K: ['generico', 'target.kids', 'Kids'],
  G: ['generico', 'target.goods', 'Goods'],
  X: ['generico', 'target.unisex', 'Unisex'],
  A: ['buscador', 'prefiltro.todos', 'See all'],
};

const LOW_STOCK_QUANTITY = 1;

const DEFAULT_CAMPER_SEO = {
  title: 'Camper',
  description: 'Buy new shoes, boots, sneakers and accessories from the collection. Contemporary designs, original spirit. Free shipping and 2 years warranty.',
};

const DEFAULT_NNORMAL_SEO = {
  title: 'NNORMAL',
  description: 'Buy new shoes, boots, sneakers and accessories from the collection. Contemporary designs, original spirit. Free shipping and 2 years warranty.',
};

const DEFAULT_SEO = IS_CAMPER ? DEFAULT_CAMPER_SEO : DEFAULT_NNORMAL_SEO;

const ADYEN_BASE_TRANSLATIONS = ({ holderName }) => ({
  'creditCard.holderName': holderName,
  'creditCard.holderName.placeholder': '',
  'creditCard.numberField.placeholder': '',
  'creditCard.expiryDateField.placeholder': '',
  'creditCard.cvcField.placeholder': '',
  'creditCard.cvcField.placeholder.4digits': '',
  'creditCard.cvcField.placeholder.3digits': '',
});

const NOT_FOUND_GIFTCARD = '000000000000';

const COVID_CONTENT_PAGE = 'Content covid-19_services_information';
const COMPANY_CONTENT_PAGE = 'Content work-with-us';
const TOGETHER_CONTENT_PAGE = 'Content together';
const HISTORY_CONTENT_PAGE_SHOES = 'Content history,shoes';
const HISTORY_CONTENT_PAGE_ORIGINS = 'Content history,origins';
const HISTORY_CONTENT_PAGE_STORES = 'Content history,stores';
const HISTORY_CONTENT_PAGE_COM = 'Content history,communication';

const CONTENT_EXTRA_CSS_PAGES = [
  COVID_CONTENT_PAGE,
  COMPANY_CONTENT_PAGE,
  TOGETHER_CONTENT_PAGE,
  HISTORY_CONTENT_PAGE_SHOES,
  HISTORY_CONTENT_PAGE_ORIGINS,
  HISTORY_CONTENT_PAGE_STORES,
  HISTORY_CONTENT_PAGE_COM,
];

const FAMILIES = {
  ALL: 0,
  SHOES: 1,
  ACCESSORIES: 2,
  CLOTHES: 5,
  GOODS: 6,
};
const FAMILIES_UNBXD = {
  shoes: '1',
  accessories: '2',
  clothes: '5',
  goods: '6',
};
const TARGETS_UNBXD = {
  K: 'Kids',
  M: 'Men',
  W: 'Women',
};

const TARGETS = {
  MEN: 'M',
  WOMEN: 'W',
  KIDS: 'K',
  GOODS: 'G',
  UNISEX: 'X',
};

const PURCHASE_ORDER_STATUS = {
  EN: 'SENT',
  PC: 'TO BE CONFIRMED',
  CA: 'CANCELLED',
  PE: 'PENDING',
  TR: 'SENT',
  FI: 'DELIVERED',
};

const RETURNS_ORDER_STATUS = {
  FI: 'ACCEPTED',
  PE: 'REGISTERED',
  CA: 'CANCELLED',
  RE: 'RECEIVED',
  EN: 'IN PROCESS',
  NA: 'NOT ACCEPTED',
};

const ORDER_TYPE = {
  PURCHASE: 'ZTON',
  RETURN: 'ZDVN',
};

const ORDER_DETAILS_ACTIONS = {
  CONFIRM_COD: 'R',
  CANCEL: 'C',
  TRACKING: 'T',
  CHANGE: 'B',
  REJECT: 'D',
};

const COUNTRIES_WITH_TAXES = IS_NNORMAL ? [] : ['US', 'RU', 'CA'];

const CALCULATE_BY_REGION = IS_NNORMAL ? [] : ['CA', 'RU'];

const KOREA_EXTRA_POLICIES_URL = {
  1: '/conditions/condiciones_uso_mis_datos_KR00.xhtml',
  2: '/conditions/condiciones_transferir_mis_datos_KR00.xhtml',
  3: '/conditions/condiciones_newsletter_KR00.xhtml',
};

const LANDINGS_CODES = {
  BLACK_FRIDAY: 'blfr',
  FAMILY_AND_FRIENDS: 'fafr',
  MID_SEASON_SALE: 'mss',
  CYBER_MONDAY: 'cym',
  SINGLE_DAY: 'sid',
  SALES: 'sales',
  ARCHIVE: 'arco',
  PRIVATE_SALE_WOMAN: 'prslw',
};

const CUSTOM_LANDING_CODES = Object.values(LANDINGS_CODES);

const CAMPAIGN_STATUS = {
  PRE: 'PRE',
  OPEN: 'OPEN',
  POST: 'POST',
};

const CAMPAIGNS_WITH_LOGO = ['prslw'];

const AUTO_BONDS = {
  APPLY: '1',
  DOESNT_APPLY: '0',
};

const MAX_BAG_LENGTH = 10;

const RUSSIA_COD_LIST_URL =
  IS_CAMPER ? 'https://www.camper.com/html/json/suburbs/russia_cod_list.json' : 'https://www.nnormal.com/html/json/suburbs/russia_cod_list.json';

const DEFAULT_VALUES_FOR_GIFTCARD = [50, 100, 150, 200, 250];

const UNISEX_PRODUCT_CODES = ['K100839-001', 'K200155-018', 'K100839-003', 'K200155-020', 'K100839-002', 'K200155-019', 'K100839-006', 'K200155-026'];

const TARGET_SIZE_LABEL_MARKETS = ['US', 'GB'];

const IMAGE_SIZE_MAPPER = {
  full: '(min-width: 1200px)',
  lg: '(min-width: 768px)',
  md: '(max-width: 767px)',
};

const BAG_EXPIRATION_DAYS = 30;

const CAMPER_GTM_ID = 'GTM-TTHX9KP';
const NNORMAL_GTM_ID = 'GTM-KHCX5VN';
const GMT_ID = IS_CAMPER ? CAMPER_GTM_ID : NNORMAL_GTM_ID;
const STREET_INPUT_MAXLENGTH = 100;

const BAZAAR_VOICE_MARKETS = ['MT'];

const STORES_WITH_SUBTITLE = ['T875', 'T176', 'D956', 'D449'];

const ECI_CODE = 'T6';

const COUNTRIES_NO_PERSONAL_SHOPPER = ['CL', 'JP'];

const FAKE_LANG_MAP = {
  mx: 'es',
  co: 'es',
  pe: 'es',
  cl: 'es',
};

const CARD_BRANDS = [
  'amex',
  'bcmc',
  'cartebancaire',
  'cup',
  'dankort',
  'diners',
  'discover',
  'electron',
  'jcb',
  'laser',
  'maestro',
  'maestrouk',
  'mc',
  'visa',
  'visadankort',
];

const STANDARD_SIZE_GUIDE = ['EU', 'US', 'UK'];
const ASIAN_MARKETS = ['TW', 'TH', 'KR', 'JP', 'HK'];

const CLOUDFRONT_MISS = 'Miss from cloudfront';

const SIZE_GUIDE_WITH_MODELS = [
  'KU10025-001',
  'KU10025-002',
  'KU10026-001',
  'KU10028-001',
  'KU10028-002',
  'KU10030-001',
  'KU10030-002',
  'KU10032-001',
  'KU10032-002',
  'KU10033-001',
  'KU10033-002',
];

const GIFT_MIN_TOTAL_PRICE = 150;

const JUNCTIONS_TOE_CAPS_SKU = ['KS00063-001', 'KS00063-002', 'KS00063-003', 'KS00063-004', 'KS00063-005'];

const MARKETS_WITH_COUNTRIES_SELECTOR = ['CA', 'DE', 'EL', 'EN', 'ES', 'FR', 'IT', 'NL', 'PL', 'PT'];

const GIFT_BAG_BASE = `https://www.${IS_CAMPER ? 'camper' : 'nnormal'}.com/html/GWP`;

const GLOBAL_E_SCRIPTS_ID = IS_CAMPER ? '799' : '30000428';

module.exports = {
  KEYCODES,
  ADYEN_STATUS,
  CAMPAIGN_COLLECTION_LIMIT,
  TIER_POSITIONS,
  PAYMENT_CODES,
  ERROR_IMG,
  SIZE_GUIDES_ORDER,
  SOCKS_TYPE,
  GIFT_CARD_CODES,
  TARGETS_TRANSLATIONS,
  LOW_STOCK_QUANTITY,
  DEFAULT_SEO,
  DEFAULT_NNORMAL_SEO,
  ADYEN_BASE_TRANSLATIONS,
  NOT_FOUND_GIFTCARD,
  CONTENT_EXTRA_CSS_PAGES,
  FAMILIES,
  FAMILIES_UNBXD,
  TARGETS_UNBXD,
  TARGETS,
  PURCHASE_ORDER_STATUS,
  RETURNS_ORDER_STATUS,
  ORDER_TYPE,
  ORDER_DETAILS_ACTIONS,
  PACKSTATION,
  PICK_UP_IN_STORE,
  CORREOS,
  POSTFILIALE,
  STANDARD,
  SHIPPING_EXPRESS,
  COUNTRIES_WITH_TAXES,
  CALCULATE_BY_REGION,
  ERROR_MINIMUM_AMOUNT,
  KOREA_EXTRA_POLICIES_URL,
  PAYPAL_CODES,
  LANDINGS_CODES,
  CUSTOM_LANDING_CODES,
  CAMPAIGN_STATUS,
  MAX_BAG_LENGTH,
  RUSSIA_COD_LIST_URL,
  AUTO_BONDS,
  DEFAULT_VALUES_FOR_GIFTCARD,
  UNISEX_PRODUCT_CODES,
  TARGET_SIZE_LABEL_MARKETS,
  IMAGE_SIZE_MAPPER,
  IS_CAMPER,
  IS_NNORMAL,
  BAG_EXPIRATION_DAYS,
  CAMPAIGNS_WITH_LOGO,
  GMT_ID,
  STREET_INPUT_MAXLENGTH,
  STORES_WITH_SUBTITLE,
  BAZAAR_VOICE_MARKETS,
  ECI_CODE,
  COUNTRIES_NO_PERSONAL_SHOPPER,
  RETURN_TO_STORE,
  RETURN_TO_DROPOFF,
  FAKE_LANG_MAP,
  CARD_BRANDS,
  STANDARD_SIZE_GUIDE,
  SAME_DAY_DELIVERY,
  SAME_DAY_DELIVERY_DISTRICTS,
  CLOUDFRONT_MISS,
  ASIAN_MARKETS,
  SIZE_GUIDE_WITH_MODELS,
  GIFT_MIN_TOTAL_PRICE,
  JUNCTIONS_TOE_CAPS_SKU,
  MARKETS_WITH_COUNTRIES_SELECTOR,
  GIFT_BAG_BASE,
  GLOBAL_E_SCRIPTS_ID,
};
