module.exports = {
  US: (t) => (locale) => [
    {
      lang: {
        locale,
        placeholder: 'MM/DD/YYYY',
        today: t('generico', 'fecha.hoy', 'Today'),
        dateFormat: 'MM/DD/YYYY',
        dateTimeFormat: 'MM/DD/YYYY',
        yearFormat: 'YYYY',
      },
      dateFormat: 'MM/DD/YYYY',
      dateTimeFormat: 'MM/DD/YYYY',
    },
  ],
  CA: (t) => (locale) => [
    {
      lang: {
        locale,
        placeholder: 'MM/DD/YYYY',
        today: t('generico', 'fecha.hoy', 'Today'),
        dateFormat: 'MM/DD/YYYY',
        dateTimeFormat: 'MM/DD/YYYY',
        yearFormat: 'YYYY',
      },
      dateFormat: 'MM/DD/YYYY',
      dateTimeFormat: 'MM/DD/YYYY',
    },
  ],
  ES: (t) => (locale) => [
    {
      lang: {
        locale,
        placeholder: 'DD/MM/YYYY',
        today: t('generico', 'fecha.hoy', 'Today'),
        dateFormat: 'DD/MM/YYYY',
        dateTimeFormat: 'DD/MM/YYYY',
        yearFormat: 'YYYY',
      },
      dateFormat: 'DD/MM/YYYY',
      dateTimeFormat: 'DD/MM/YYYY',
    },
  ],
  JP: (t) => (locale) => [
    {
      lang: {
        locale,
        placeholder: 'MM/DD/YYYY',
        today: t('generico', 'fecha.hoy', 'Today'),
        dateFormat: 'MM/DD/YYYY',
        dateTimeFormat: 'MM/DD/YYYY',
        yearFormat: 'YYYY',
      },
      dateFormat: 'MM/DD/YYYY',
      dateTimeFormat: 'MM/DD/YYYY',
    },
  ],
};
