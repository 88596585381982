/**
 * Function that return the error from the response
 * @param {*} data
 * The error in the data follows this structure:
 * {
    "status": "KO",
    "errors": [
        {
            "code": "E_VALIDATION",
            "message": "profile no debe ser nulo"
        }
    ]
}
  * @returns the first error in the array if it exists or a default error object
 */
export function getErrorFromResponse(data) {
  if (data?.errors?.length > 0) {
    return data.errors[0];
  }
  return {
    code: 'MISSING_ERROR',
    message: 'Unexpected Error',
  };
}

export function isDefaultError(code) {
  return code === 'MISSING_ERROR';
}

export function checkForServerError(r) {
  if (typeof r === 'object' && r !== null) {
    return 'status' in r && r.status === 'KO';
  }
  return false;
}

export function getServerError(r, defaultError) {
  if (r.errors?.[0] !== undefined) {
    const e = {
      ...r.errors[0],
      code: r.errors[0].code.toLowerCase(),
    };
    return e;
  }
  return defaultError ?? { code: 'unknown_error', message: 'unknown error' };
}

export async function handleFetchResponse(response, defaultError) {
  const isJson = response.headers.get('content-type')?.includes('application/json');

  if (!isJson) {
    return { ok: false, error: { code: 'json_error', message: 'response was not json' } };
  }
  if (!response.ok) {
    let error = await response.json();
    error = getServerError(error, defaultError);
    return { ok: false, error };
  }
  let json = null;
  try {
    json = await response.json();
  } catch (e) {
    return { ok: false, error: { code: 'json_error', message: 'response was not json' } };
  }
  if (checkForServerError(json)) {
    return { ok: false, error: getServerError(json) };
  }
  return { ok: true, json };
}

export function createRequestUrl({ path, base = process.env.API_GATEWAY, query = {} }) {
  const url = new URL(path, base);
  Object.keys(query).forEach((key) => url.searchParams.append(key, query[key]));
  return url;
}

/*
 * @param {string | null} accept
 * @returns {{locale: string | null, language: string | null, country: string | null}}
 */
export function parseAcceptLanguageHeader(accept) {
  if (accept === null || accept === undefined) {
    return {
      locale: null,
      language: null,
      country: null,
    };
  }
  const acceptString = accept.split(';')[0];

  const localeMatch = new RegExp(/([a-z]){2}-[A-Z]{2}?/).exec(acceptString);
  if (Array.isArray(localeMatch)) {
    const [language, country] = localeMatch[0].split('-');
    return {
      locale: localeMatch[0],
      language,
      country,
    };
  }
  // if we only find language in the accept string
  const languageMatch = new RegExp(/([a-z]){2}/).exec(acceptString);
  if (Array.isArray(languageMatch)) {
    return {
      locale: null,
      language: languageMatch[0],
      country: null,
    };
  }

  return {
    locale: null,
    language: null,
    country: null,
  };
}
