const BAZAAR_VOICE_MARKETS = [ 'MT' ];

const IMAGE_SIZE_MAPPER = {
  full: '(min-width: 1200px)',
  lg: '(min-width: 768px)',
  md: '(max-width: 767px)',
};

const KEYCODES = {
  arrowDown: 'ArrowDown',
  arrowUp: 'ArrowUp',
  enter: 'Enter',
  escape: 'Escape',
  space: 'Space',
  tab: 'Tab',
};

const STREET_INPUT_MAXLENGTH = 100;

module.exports = {
  BAZAAR_VOICE_MARKETS,
  IMAGE_SIZE_MAPPER,
  KEYCODES,
  STREET_INPUT_MAXLENGTH,
};
