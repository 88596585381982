const { createTranslations } = require('../actions/general');
const { setCookie, COOKIES } = require('./helpers');
/**
 *
 * @param {string} locale
 * @param {string} labels
 * @param {string} parent
 * @param {string} anchor
 * @param {string} defaultValue
 * @returns
 */
const t = (locale, labels, parent, anchor, defaultValue) => {
  parent = parent.trim().toLowerCase().replace(/[_\s]/g, '.');
  anchor = anchor.trim().toLowerCase().replace(/[_\s]/g, '.');
  defaultValue = defaultValue === undefined ? null : defaultValue;

  let returnValue = `${parent}.${anchor}`;

  if (Array.isArray(labels)) {
    if (labels.length > 0) {
      const foundKey = labels.filter((item) => item.parentId === parent && item.anchorId === anchor);
      if (foundKey.length > 0) {
        returnValue = foundKey[0].value;
      }
    }
  }

  if (![ 'default', 'int' ].includes(locale) && returnValue === `${parent}.${anchor}`) {
    try {
      createTranslations(locale, [
        {
          parentId: parent,
          anchorId: anchor,
          profileId: locale,
          value: defaultValue,
        },
      ]);
    } catch (error) {
      console.warn(error);
    }
  }

  if (
    parent === 'bolsa' &&
    anchor.includes('only.standard.delivery') &&
    returnValue === `${parent}.${anchor}`
  ) {
    returnValue = '';
  }

  return returnValue || '';
};

const newT = (labels, parent, anchor) => {
  const defaultReturn = `${parent}.${anchor}`;
  let foundKey = null;

  try {
    if (labels !== undefined) {
      parent = parent.trim().toLowerCase().replace(/[_\s]/g, '.');
      anchor = anchor.trim().toLowerCase().replace(/[_\s]/g, '.');

      const parentObject = labels[parent];

      if (parentObject) {
        const anchorText = parentObject[anchor];

        if (anchorText !== undefined) {
          foundKey = anchorText;
        }
      }

      const returnObject = { foundKey, defaultReturn };

      return returnObject;
    }

    return { foundKey, defaultReturn };
  } catch (e) {
    console.log('[I18N] ERROR', labels, parent, anchor);
    return { foundKey, defaultReturn };
  }
};

function setGBCookie(value) {
  setCookie(COOKIES.GB_COUNTRY, value, 29);
}

module.exports = {
  t, newT, setGBCookie,
};
