import { useEffect } from 'react';

const key = 'session_utm_params';

type UtmParams = {
  campaign?: string | null;
  channel?: string;
  medium?: string;
};

export function useUtmParams() {
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const referrer = document.referrer;
    const objectToSave: UtmParams = {
      campaign: null,
      channel: 'direct',
      medium: 'direct',
    };
    if (referrer.includes('google')) {
      objectToSave.channel = 'SEO';
      objectToSave.medium = 'google';
    }
    if (referrer.includes('bing')) {
      objectToSave.channel = 'SEO';
      objectToSave.medium = 'bing';
    }
    if (searchParams.size > 0) {
      if (searchParams.get('gclid') !== null) {
        objectToSave.channel = 'cpc';
        objectToSave.medium = 'google';
      }
      if (searchParams.get('utm_campaign') !== null) {
        objectToSave.campaign = searchParams.get('utm_campaign');
      }
      if (searchParams.get('utm_channel') !== null) {
        objectToSave.channel = searchParams.get('utm_channel');
      }
      if (searchParams.get('utm_medium') !== null) {
        objectToSave.medium = searchParams.get('utm_medium');
      }
    }
    sessionStorage.setItem(key, JSON.stringify(objectToSave));
  }, []);
}

export function getUtmParams({ campaign }: { campaign?: string } = {}) {
  const utmParams = sessionStorage.getItem(key);
  if (utmParams) {
    // TODO: validate object with valibot, zod or similar
    const parsed = JSON.parse(utmParams);
    // check if parsed is an object
    if (parsed && typeof parsed === 'object') {
      if (!parsed?.campaign && campaign) {
        parsed.campaign = campaign;
      }
      return parsed as UtmParams;
    }
  }
  return {} as UtmParams;
}
