export const ChevronSVG = (props) => (
  <svg height={9} viewBox="0 0 18 9" width={18} {...props}>
    <path
      d="m4-4 3.67495264 3.67495264 4.32504736 4.32504736-8 8"
      fill="none"
      stroke="currentColor"
      strokeLinecap="square"
      transform="matrix(0 1 -1 0 12.96 -3.141463)"
    />
  </svg>
);
