import { useContext, useState } from 'react';
import {
  array, func, object, string,
} from 'prop-types';
import { Select } from 'antd';

import { formatShipmentCost } from 'utils/helpers';
import { ChevronSVG } from 'components/iconsSVG/chevron';
import { i18nContext } from 'context/i18n';
import { dataLayerHandleEvent } from 'utils/dataLayers';

import styles from './style.module.css';

const { Option } = Select;

const CamperSelect = ({
  currentShipments, defaultSelectedShipment, market, handleClickShipment,
}) => {
  const { t } = useContext(i18nContext);
  const [ dropdownVisible, setDropdownVisible ] = useState(false);

  const renderLabel = (shipment) => {
    if (shipment === 'choose') {
      return (
        <div className={styles.selectShipment} onClick={() => setDropdownVisible(!dropdownVisible)}>
          <p>
            <span>
              {t('bolsa', 'select.an.option')}
            </span>
            <span className={styles.chevronActive}>
              <ChevronSVG />
            </span>
          </p>
        </div>
      );
    }

    const handleClickDropdown = () => {
      if (window?.clickedPromo !== true) {
        dataLayerHandleEvent({
          event: 'start_promo_code',
          event_category: 'bag',
          event_detail_1: 'del',
          event_detail_2: '',
        });
        window.clickedPromo = true;
      }

      setDropdownVisible(true);
    };

    return (
      <div className={styles.shipmentWrapper} onClick={handleClickDropdown}>
        <p className={styles.selectLabelP}>
          <span>{shipment.name}</span><br/>
          <span className={styles.shipmentBtnDescription} dangerouslySetInnerHTML={{ __html: shipment.text }}></span>
        </p>
        <span className={styles.shipmentPrice}>
          {formatShipmentCost(shipment.shipmentsCostsPrice.shipmentPrice, market, t, shipment.shipmentsCostsPrice.currency)}
          <ChevronSVG />
        </span>
      </div>
    );
  };
  const handleOnSelect = (value) => {
    setDropdownVisible(false);
    handleClickShipment(value);
  };

  if (currentShipments.length === 1) {
    const shipment = currentShipments[0];
    return (
      <div className={`${styles.shipmentItem} ${styles.uniqueShipment}`}>
        <p>
          <span>{shipment.name}</span><br/>
          <span className={styles.shipmentBtnDescription} dangerouslySetInnerHTML={{ __html: shipment.text }}></span>
        </p>
        <span>{formatShipmentCost(shipment.shipmentsCostsPrice.shipmentPrice, market, t, shipment.shipmentsCostsPrice.currency)}</span>
      </div>
    );
  }
  return (
    <div className={`${styles.customSelect}`}>
      <Select
        dropdownAlign={{
          offset: [ 0, -2 ],
        }}
        labelInValue={true}
        value={{
          value: defaultSelectedShipment.name,
          label: !dropdownVisible ? renderLabel(defaultSelectedShipment) : renderLabel('choose'),
        }}
        showArrow={false}
        open={dropdownVisible}
        onBlur={() => setDropdownVisible(false)}
        dropdownStyle={{
          padding: 0,
        }}
        dropdownClassName={styles.customSelectDropdown}
        onSelect={(value) => handleOnSelect(value.value)}
      >
        {currentShipments.map((shipment) => (
          <Option key={shipment.id} value={ shipment.name !== '' ? shipment.name : shipment.text}>
            <div className={styles.shipmentItem}>
              <p>
                <span>{shipment.name}</span><br/>
                <span className={styles.shipmentBtnDescription} dangerouslySetInnerHTML={{ __html: shipment.text }}></span>
              </p>
              <span>{formatShipmentCost(shipment.shipmentsCostsPrice.shipmentPrice, market, t, shipment.shipmentsCostsPrice.currency)}</span>
            </div>
          </Option>
        ))}
      </Select>
    </div>
  );
};

CamperSelect.propTypes = {
  currentShipments: array,
  defaultSelectedShipment: object,
  market: string,
  handleClickShipment: func,
};

export default CamperSelect;
