/* eslint-disable class-methods-use-this */
import axios from 'axios';
import { IS_CAMPER } from './constants/system';
import { COOKIES, getCookie } from './helpers';

// si uso destructuring con dos variables me da un error de process is undefined (???)
/* eslint-disable prefer-destructuring */
const API_GATEWAY = process.env.API_GATEWAY;
const bases = { API_GATEWAY };
const defaultHeaders = {};

const handleSuccess = (config) => {
  if (config && config.data) {
    return config;
  }

  return Promise.reject(config);
};

export const handleError = (error) => {
  const date = new Date();
  console.error(`${date.toLocaleDateString()} ${date.toLocaleTimeString()} -> Error in API / ${error?.config?.method} -> ${error?.config?.url}`);

  return {
    data: {
      status: 'KO',
      error: error ? String(error) : 'Unexpected Error',
    },
  };
};

/**
 *
 * @param {Object} headers,
 * @returns Object
 */
const prepareHeaders = (headers) => {
  let returnHeaders = { ...defaultHeaders };

  if (headers !== undefined && headers !== null && Object.keys(headers).length > 0) {
    returnHeaders = { ...returnHeaders, ...headers };
  }
  if (process.env.ENVIRONMENT === 'local') {
    const clientId = getCookie(COOKIES.CLIENT);
    if (!returnHeaders[COOKIES.CLIENT]) {
      returnHeaders[COOKIES.CLIENT] = clientId;
    }
  }

  return returnHeaders;
};

const getUrl = (base, path) => {
  if (bases[base] !== undefined) {
    return bases[base] + path;
  }
  if (base !== undefined) {
    return base + path;
  }

  return API_GATEWAY + path;
};

export const getPrivateUrl = (environment) => {
  if (environment === 'local' || environment === 'integra') {
    return IS_CAMPER ? 'https://api-private-integra.camper.com/eshop-api/private/api/v1' : 'https://api-private-integra.nnormal.com/eshop-api/private/api/v1';
  }
  return IS_CAMPER ? 'https://api-private.camper.com/eshop-api/private/api/v1' : 'https://api-private.nnormal.com/eshop-api/private/api/v1';
};

const wrapper = axios.create();

wrapper.interceptors.request.use((config) => {
  const clientUrl = config.headers['client-url'];

  if (clientUrl?.includes('//www-interna.')) {
    config.url = config.url.replace('//www.', '//www-interna.');
  }

  return config;
}, (error) => Promise.reject(error));

wrapper.interceptors.response.use(handleSuccess, handleError);

export const get = ({
  base, path, headers,
}) => wrapper.request({
  method: 'GET',
  headers: prepareHeaders(headers),
  url: getUrl(base, path),
});

export const post = ({
  base, path, data, headers,
}) => wrapper.request({
  method: 'POST',
  headers: prepareHeaders(headers),
  url: getUrl(base, path),
  responseType: 'json',
  data,
});

export const handleApiResponse = (response) => new Promise((resolve, reject) => {
  let errorToLog = null;
  let dataToUse = undefined;

  if ('data' in response) {
    const { data } = response;
    dataToUse = data;

    if ('status' in data) {
      const { status, errors } = data;

      if (status !== 'OK') {
        const errorsToUse = errors || [];
        const statusNotOkError = new Error(`[API ERROR ${status}] - ${errorsToUse.join(' ')}`);
        errorToLog = statusNotOkError;
      }
    }

    const noStatusError = new Error('[API ERROR] - No status from API endpoint.');
    errorToLog = noStatusError;
  }

  const noDataError = new Error('[API ERROR] - No data from API endpoint.');
  errorToLog = noDataError;

  if (errorToLog !== null) {
    console.error(errorToLog);
    return reject(dataToUse);
  }

  return resolve(dataToUse);
});
