import { useCallback } from 'react';
import { useI18n } from 'context/i18n';
import { BRITISH_COUNTRIES } from 'utils/constants/i18n';
import { useLocale } from './useLocale';

export function useCountries() {
  const { countries } = useI18n();
  const { locale, country } = useLocale();

  const isCountry = useCallback((countryCode) => {
    try {
      if (Array.isArray(countryCode)) {
        if (!countryCode.every((c) => typeof c === 'string')) {
          throw new Error('useLocale: if an array is provided, all elements must be strings');
        }
        return countryCode.some((c) => c === country);
      }
      if (typeof countryCode !== 'string') {
        throw new Error('useLocale: countryCode must be a string or an array of strings');
      }
      return countryCode === country;
    } catch (error) {
      console.error(error);
      return false;
    }
  }, [ country ]);

  const isBritishCountry = useCallback((code = country) => BRITISH_COUNTRIES.includes(code ?? ''), [ country ]);

  const getCountriesData = useCallback((wantedCountries) => countries?.filter((c) => wantedCountries.includes(c.id)) ?? [], [ countries ]);

  return {
    locale, isBritishCountry, isCountry, getCountriesData,
  };
}
