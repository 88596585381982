import { get, post } from 'utils/apiWrapper';
import { prepareHeadersFromContext, handleOriginByCookie } from 'utils/helpers';
import {
  LOGIN_ROUTE,
  USER_INFO_ROUTE,
  CREATE_ACCOUNT,
  MODIFY_ACCOUNT,
  CHANGE_PASSWORD,
  EMPLOYEE_LOGIN_ROUTE,
  RECOVER_PASSWORD,
  SET_NEW_PASSWORD,
  REGISTER_PELOTAS,
  USERS_CUSTOMER_SERVICE,
  ADD_PRODUCT_TO_WISHLIST,
  GET_WISHLIST,
  REMOVE_PRODUCT_FROM_WISHLIST,
  NEWSLETTER_CHECK_STATUS,
  DELETE_MY_ACCOUNT,
  STORE_LOGIN,
  STORE_EMPLOYEES,
} from 'utils/routes';
import { getUtmParams } from 'utils/utm';

export const login = (username, password) =>
  new Promise((resolve, reject) => {
    post({
      path: LOGIN_ROUTE,
      data: {
        username,
        password,
      },
    })
      .then((response, error) => {
        try {
          if (response && response.data) {
            const { status, token, refreshToken, info } = response.data;
            if (status === 'OK') {
              return resolve({ token, refreshToken, info });
            }
          }
          return reject(error);
        } catch (e) {
          return reject(e);
        }
      })
      .catch(reject);
  });

export const createAccount = (locale, accountData) =>
  new Promise((resolve, reject) => {
    const originFields = handleOriginByCookie();
    const utmParams = getUtmParams();

    post({
      path: CREATE_ACCOUNT,
      data: {
        profile: locale,
        ...accountData,
        ...originFields,
        ...utmParams,
      },
    })
      .then((response) => {
        if (response && response.data && response.data.status === 'OK') {
          try {
            resolve(accountData);
          } catch (e) {
            reject(e);
          }
        } else {
          reject(response.data.errors[0].message);
        }
      })
      .catch(reject);
  });

export const getUserData = (locale, showOrders) =>
  new Promise((resolve, reject) => {
    const token = localStorage.getItem('token');
    const body = {
      profile: locale,
    };
    if (typeof showOrders === 'boolean') {
      body.showOrders = showOrders;
    }
    post({
      path: `${USER_INFO_ROUTE}`,
      headers: { Authorization: `Bearer ${token}` },
      data: body,
    })
      .then((response) => {
        const { status, account, lastOrder, orders } = response.data;
        if (status === 'OK') {
          return resolve({ account, lastOrder, orders });
        }

        return reject(response);
      })
      .catch(reject);
  });

export const modifyUserData = (locale, data) =>
  new Promise((resolve, reject) => {
    const token = localStorage.getItem('token');
    post({
      path: MODIFY_ACCOUNT,
      headers: { Authorization: `Bearer ${token}` },
      data: {
        profile: locale,
        account: data,
      },
    })
      .then((response) => {
        if (response && response.data) {
          const { status, errors } = response.data;

          if (status === 'OK') {
            resolve();
          } else {
            reject(errors);
          }
        }
      })
      .catch(reject);
  });

export const changePassword = (oldPassword, newPassword) =>
  new Promise((resolve, reject) => {
    const token = localStorage.getItem('token');
    post({
      path: CHANGE_PASSWORD,
      headers: { Authorization: `Bearer ${token}` },
      data: { oldPassword, newPassword },
    }).then((response) => {
      if (response && response.data) {
        const { status, errors } = response.data;

        if (status === 'OK') {
          resolve(response.data);
        } else {
          reject(errors);
        }
      }
    });
  });

export const employeeLogin = (payload) =>
  new Promise((resolve, reject) => {
    post({
      path: EMPLOYEE_LOGIN_ROUTE,
      data: payload,
    })
      .then((response, errors) => {
        try {
          if (response && response.data) {
            const { status, userCode, pending, max, details, currency, acumulated } = response.data;
            if (status === 'OK') {
              return resolve({
                userCode,
                pending,
                max,
                details,
                currency,
                acumulated,
              });
            }
          }
          return reject(errors);
        } catch (e) {
          return reject(e);
        }
      })
      .catch(reject);
  });

export const recoverPassword = (payload) =>
  new Promise((resolve, reject) => {
    post({
      path: RECOVER_PASSWORD,
      data: payload,
    })
      .then((response, errors) => {
        try {
          if (response && response.data) {
            if (response.data.status === 'OK') {
              return resolve(response.data);
            }
          }
          return reject(errors);
        } catch (e) {
          return reject(e);
        }
      })
      .catch(reject);
  });

export const setNewPassword = (payload) =>
  new Promise((resolve, reject) => {
    post({
      path: SET_NEW_PASSWORD,
      data: payload,
    })
      .then((response, errors) => {
        try {
          if (response && response.data) {
            if (response.data.status === 'OK' && response.data.message.indexOf('succes') !== -1) {
              return resolve(response.data);
            }
          }
          return reject(errors);
        } catch (e) {
          return reject(e);
        }
      })
      .catch(reject);
  });

export const registerPelotas = (payload) =>
  new Promise((resolve, reject) => {
    const token = localStorage.getItem('token');
    const formdata = new FormData();
    Object.entries(payload).forEach((value) => {
      if (value[0] === 'ticket') {
        value[1].forEach((file) => formdata.append(value[0], file));
      } else {
        formdata.append(value[0], value[1]);
      }
    });
    post({
      path: REGISTER_PELOTAS,
      data: formdata,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response, errors) => {
        try {
          if (response && response.data) {
            const { status, message } = response.data;
            if (status === 'OK') {
              return resolve({
                status,
                message,
              });
            }
          }
          return reject(errors);
        } catch (e) {
          return reject(e);
        }
      })
      .catch(reject);
  });

export const getUsersCustomerService = ({ context }) =>
  new Promise((resolve, reject) => {
    const headers = prepareHeadersFromContext(context);

    get({
      path: USERS_CUSTOMER_SERVICE,
      headers,
    })
      .then((response, errors) => {
        try {
          if (response && response.data) {
            if (response.data.status === 'OK') {
              return resolve(response.data);
            }
          }
          return reject(errors);
        } catch (e) {
          return reject(e);
        }
      })
      .catch(reject);
  });

export const addProductToWishlist = (payload) =>
  new Promise((resolve, reject) => {
    const token = localStorage.getItem('token');
    post({
      path: ADD_PRODUCT_TO_WISHLIST,
      headers: { Authorization: `Bearer ${token}` },
      data: payload,
    }).then((response) => {
      if (response && response.data) {
        const { status, errors } = response.data;

        if (status === 'OK') {
          resolve(response.data);
        } else {
          reject(errors);
        }
      }
    });
  });

export const removeProductFromWishlist = (payload) =>
  new Promise((resolve, reject) => {
    const token = localStorage.getItem('token');
    post({
      path: REMOVE_PRODUCT_FROM_WISHLIST,
      headers: { Authorization: `Bearer ${token}` },
      data: payload,
    }).then((response) => {
      if (response && response.data) {
        const { status, errors } = response.data;

        if (status === 'OK') {
          resolve(response.data);
        } else {
          reject(errors);
        }
      }
    });
  });

export const getWishlist = (payload) =>
  new Promise((resolve, reject) => {
    const token = localStorage.getItem('token');
    post({
      path: GET_WISHLIST,
      headers: { Authorization: `Bearer ${token}` },
      data: payload,
    }).then((response) => {
      if (response && response.data) {
        const { status, errors } = response.data;

        if (status === 'OK') {
          resolve(response.data);
        } else {
          reject(errors);
        }
      }
    });
  });

export const checkNewsletterStatus = (profile, email) =>
  new Promise((resolve, reject) => {
    post({
      path: NEWSLETTER_CHECK_STATUS,
      data: {
        profile,
        email,
      },
    })
      .then((response, error) => {
        try {
          if (response && response.data) {
            const { status, newsletterSubscribed, hasPhone, hasName } = response.data;
            if (status === 'OK') {
              return resolve({
                status,
                newsletterSubscribed,
                hasPhone,
                hasName,
              });
            }
          }
          return reject(error);
        } catch (e) {
          return reject(e);
        }
      })
      .catch(reject);
  });

export const deleteMyAccount = (locale) =>
  new Promise((resolve, reject) => {
    const token = localStorage.getItem('token');
    post({
      path: DELETE_MY_ACCOUNT,
      headers: { Authorization: `Bearer ${token}` },
      data: {
        profile: locale,
        token,
      },
    })
      .then((response) => {
        if (response && response.data) {
          const { status, errors, message } = response.data;

          if (status === 'OK') {
            resolve({ message });
          } else {
            reject(errors);
          }
        }
      })
      .catch(reject);
  });

export const storeLogin = (payload) =>
  new Promise((resolve, reject) => {
    post({
      path: STORE_LOGIN,
      data: payload,
    })
      .then((response) => {
        if (response && response?.data && response?.data?.status === 'OK') {
          delete response.data.status;
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch(reject);
  });

export const getEmployees = (profile, storeId) =>
  new Promise((resolve, reject) => {
    post({
      path: STORE_EMPLOYEES,
      data: { storeId, profile },
    })
      .then((response) => {
        if (response && response?.data && response?.data?.status === 'OK' && response?.data?.empleados) {
          resolve(response.data.empleados);
        } else {
          reject(response);
        }
      })
      .catch(reject);
  });
