const { IS_CAMPER } = require('./system');

const DEFAULT_CAMPER_SEO = {
  title: 'Camper',
  description: 'Buy new shoes, boots, sneakers and accessories from the collection. Contemporary designs, original spirit. Free shipping and 2 years warranty.',
};

const DEFAULT_NNORMAL_SEO = {
  title: 'NNORMAL',
  description: 'Buy new shoes, boots, sneakers and accessories from the collection. Contemporary designs, original spirit. Free shipping and 2 years warranty.',
};

const DEFAULT_SEO = IS_CAMPER ? DEFAULT_CAMPER_SEO : DEFAULT_NNORMAL_SEO;

module.exports = {
  DEFAULT_SEO,
  DEFAULT_NNORMAL_SEO,
};
