module.exports = {
  // CAMPER RED COLOR
  primaryDarker: '#BA0B10',
  primaryDark: '#CA1821',
  primary: '#D5212C',
  primaryLight: '#ED4049',
  primaryLighter: '#F2BCC0',

  // GRAY
  grayDarker: '#636363',
  gray: '#919191',
  grayLighter: '#DEDEDE',
  backgroundGray: '#F6F6F6',

  // BLACK AND WHITE
  black: '#080808',
  lightBlack: '#333',
  white: '#FFFFFF',

  // SUCCESS
  success: '#45E513',
  backgroundSuccess: '#CCFFCC',

  // SIZES
  xs: '450px',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  xxl: '1440px',
};
