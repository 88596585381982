
export function formatProductsForCheckout(products: any[]) {
  const productsCopy = JSON.parse(JSON.stringify(products));
  const productsWithParent = productsCopy.reduce((acc, product) => {
    if (!!product.parentId) {
      if (acc[product.parentId]) {
        acc[product.parentId].push(product);
      } else {
        acc[product.parentId] = [product];
      }
    }
    return acc;
  }, {});
  const newProducts = products.reduce((acc, product) => {
    const { code, productId, size, price, camperoneOrderId, giftCard, parentId, id } = product;
    const productToReturn: Record<string, any> = {
      id: productId || code,
      size,
      price: price.current,
      camperoneOrderId: camperoneOrderId || null,
      giftCard: giftCard || null,
    }
    // si no tiene parent id devolvemos
    if (!parentId) {
      if (productsWithParent[id]) {
        productToReturn.additionalProducts = [];
        productsWithParent[id].forEach((additionalProduct: any) => {
          productToReturn.additionalProducts.push(additionalProduct.productId || additionalProduct.code);
          productToReturn.price += additionalProduct.price.current;
        });
      }
      acc.push(productToReturn);
    }
    return acc;
  }, []);
  return newProducts;
}